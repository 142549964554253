<template>
  <div class="flex flex-col h-full flex-1 pt-8 pb-4 md:pt-16 md:pb-8 bg-white md:px-0 px-4">
    <OrganismsAuthenticationPostSale
      v-if="psRegistration"
      :class="{'max-w-[26.5rem]':psRegistration}"
      :user-information="userInformation" />
    <OrganismsAuthentication
      v-else
      auth-route="login"
      @ps-registration="initPsRegistration" />
  </div>
</template>

<script setup>
defineOptions({
  name: 'Login'
})
definePageMeta({
  middleware: ['mh-middleware']
})
const route = useRoute()
generateMeta({
  title: 'Login | Compare Club',
  ogTitle: 'Login | Compare Club',
  description: 'We make it quick and easy for Australians to get more value for money on their biggest household expenses.',
  ogDescription: 'We make it quick and easy for Australians to get more value for money on their biggest household expenses.',
  ogUrl: 'https://compareclub.com.au/login/',
  ogImage: '',
  ogType: 'corporate'
}, pageIsFunnel(route))
const userInformation = ref({
  name: '',
  email: ''
})
const psRegistration = ref(false)
/*
 * Initializes the post-sale registration process.
 * This function sets the `psRegistration` flag to true, indicating that the post-sale registration process has started.
 * It updates the `userInformation` object with the user's name and email from the provided payload.
 * Finally, it sets `emptyPage` to false, making the page content visible.
 *
 * @param {Object} payload - The data object containing the user's information.
 * @param {string} payload.name - The name of the user.
 * @param {string} payload.email - The email of the user.
 */
function initPsRegistration (payload) {
  psRegistration.value = true
  userInformation.value = { name: payload?.name, email:payload?.email }
}
</script>
